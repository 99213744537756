@font-face {
  font-family: 'nonid-icon';
  src:  url('fonts/nonid-icon.eot?10usmn');
  src:  url('fonts/nonid-icon.eot?10usmn#iefix') format('embedded-opentype'),
    url('fonts/nonid-icon.ttf?10usmn') format('truetype'),
    url('fonts/nonid-icon.woff?10usmn') format('woff'),
    url('fonts/nonid-icon.svg?10usmn#nonid-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="nonid-icon-"], [class*=" nonid-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'nonid-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nonid-icon-human-resources:before {
  content: "\e900";
}
.nonid-icon-training:before {
  content: "\e901";
}
.nonid-icon-strategy:before {
  content: "\e902";
}
.nonid-icon-left-arrow:before {
  content: "\e903";
}
.nonid-icon-visualization:before {
  content: "\e904";
}
.nonid-icon-target:before {
  content: "\e905";
}
.nonid-icon-presentation:before {
  content: "\e906";
}
.nonid-icon-laptop:before {
  content: "\e907";
}
