.side-drawer {
  font-family: "Poppins", sans-serif;
  height: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 0px 7px rgba(255, 94, 0, 0.226);
  position: fixed;
  top: 0;
  left: 0;
  width: 82%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-102%);
  transition: transform 0.3s ease-out;
  overflow: scroll;
}
.side-drawer.open {
  transform: translateX(0);
}

.logo-drawer {
  display: flex;
  margin-bottom: 2rem;
}
.side-drawer ul {
  height: 90%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.side-drawer a {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: #666e82;
}
a:hover {
  color: #ff8257;
}
.side-drawer li {
  border-radius: 1.2rem;
  margin-left: 6px;
  margin-bottom: 10px;
}

@media (min-width: 899px) {
  .side-drawer {
    display: none;
  }
}
