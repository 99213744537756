.form {
  margin: 0px auto;
  border-radius: 20px;
  min-height: 900px;
  max-width: 1000px;
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #f1ded575;
  box-shadow: 1px 20px 20px 5px rgba(228, 179, 115, 0.39);
}
.form:hover {
  box-shadow: 1px 20px 20px 5px rgba(226, 172, 102, 0.568);
  transition: all 0.3s ease-in-out;
}
.input {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-bottom: 50px;
}
.phoneNumber input {
  width: 250px !important;
}
textarea:focus,
input:focus {
  outline: none;
}
.form h1 {
  color: coral;
  margin-bottom: 40px;
}
@media (max-width: 500px) {
  .input input,
  textarea {
    width: 250px !important;
  }
  .form {
    padding: 40px;
    min-height: 800px;
  }

  .invalid p {
    font-size: 13px !important;
  }
  .form h1 {
    margin-top: 10px !important;
    padding-bottom: 15px !important;
    font-size: 30px !important;
  }
  .phoneNumber input {
    width: 200px !important;
  }
}
@media (max-width: 1000px) {
  .form {
    margin: 15px;
  }

  .form h1 {
    padding: 2px;
    margin-bottom: -2px;
    margin-top: -35px;
    font-size: 36px;
    text-align: center;
  }
}

.input p {
  display: inline;
  font-size: 20px;
  font-weight: 600;
  margin-left: 5px;
  padding-top: 10px;
  color: #2e3d62;
}
.input input,
textarea {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-bottom: 2px solid coral;
  border-radius: 5px;
  padding: 10px;
}
.invalid p {
  font-size: 15px;
  font-weight: normal;
  color: red;
}
