.calendly {
  padding: 100px;
  height: 1200px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -100px;
  margin-bottom: -200px;
}

.calendly iframe {
  min-height: 90%;
  min-width: 62%;
}
@media (max-width: 700px) {
  .calendly {
    margin-bottom: -220px;
  }
  .calendly iframe {
    min-height: 82%;
  }
}
