@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900");
@import url("bootstrap.min.css");
@import url("font-awesome.min.css");
@import url("owl.carousel.css");
@import url("animate.css");
/* @import url("owl.theme.default.min.css"); */
@import url("jquery.bxslider.min.css");
@import url("../plugins/nonid-icon/style.css");
/*
* 2. common styles
*/
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 34px;
  color: #666e82;
}
.u-card {
  display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: column;
  gap: 50px;
  margin-top: 60px;
}
.u-card-item {
  min-height: 300px;
  max-width: 1150px;
  border-radius: 5px;
  background-color: white;

  box-shadow: 1px 4px 3px 4px rgba(114, 114, 114, 0.123);
}
.u-card-item:hover {
  box-shadow: 1px 4px 10px 4px rgba(114, 114, 114, 0.205);
}
.u-card-heading {
  display: flex;
  align-items: center;
  color: #2e3d62;
  gap: 20px;
  display: flex;
  margin-top: 50px;
  text-align: left;
  padding: 0 8%;
}
.u-card-heading img {
  height: 50px;
  width: 50px;
  margin-top: -10px;
}
.u-card-content {
  display: flex;
  padding: 35px 8%;
  text-align: left;
}
.u-card-border {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 400;
  border-radius: 5px;
  box-shadow: 1px 4px 3px 4px rgba(114, 114, 114, 0.123);
}
.u-card-border:hover {
  box-shadow: 1px 4px 10px 4px rgba(114, 114, 114, 0.356);
}

.benifit-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 60px;
}
.benifit-card-item {
  color: #2e3d62;
  max-width: 950px;
  border-radius: 5px;
}
.benifit-card-heading {
  margin-top: 10px;
  padding: 0px 3%;
  text-align: left;
  border-bottom: 2px solid orange;
}
.benifit-card-content {
  display: flex;
  padding: 25px 3%;
  text-align: left;
  background-color: #feeee7;
}

.contcard {
  height: 90%;
  margin-top: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px;
}
.contcard .card {
  position: relative;
  max-height: 500px;
  background-color: #fdfbf7;
  border: hidden;
  margin: 30px 10px;
  padding: 40px 35px;
  display: flex;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;
}
.contcard .card :focus {
  outline: none;
}
.contcard .card .imgBx {
  border: none;
  position: relative;
  height: 200px;
  top: -80px;
  z-index: 1;
}
.contcard .card .imgBx img {
  height: 450px;
  width: 230px;
  box-shadow: 4px 16px 16px 4px rgba(196, 153, 90, 0.527);
  border-radius: 22px;
}
.contcard .card .content {
  position: relative;
  height: 300px;
  margin-top: -80px;
  padding: 1px 1px;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
.contcard .card:hover .content {
  cursor: pointer;
  visibility: visible;
  opacity: 1;
  margin-top: 200px;
  transition-delay: 0.3s;
}
.contcard .card:hover .show {
  visibility: hidden;
}
.hform {
  display: none;
}
.contcard .card .imgBx img:hover {
  box-shadow: 6px 16px 16px 6px rgba(177, 111, 13, 0.548);
  cursor: pointer;
  transition: 0.4s ease-in-out;
  transform: scale(1.05);
}
.contcard .card .imgBx .flcenth a:hover {
  transition: 0.3s ease-in-out;
  transform: scale(1.1);
}
.flcent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.flcent p {
  margin-top: 20px;
  margin-bottom: 2px;
  color: coral;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}
.flcenth {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.flcenth a {
  margin-top: 20px;
  margin-bottom: 2px;
  color: coral;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}

.PhoneInputInternationalIconPhone {
  /* display: none; */
  opacity: 0.3 !important;
}
.PhoneInputInternationalIconGlobe {
  /* background-image: url(../images/logo-1-1.png) !important; */
  opacity: 1 !important;
}
.main-navigation a:active {
  color: coral !important;
}
.navigation-box a:active {
  color: coral !important;
}

.visionh {
  display: none;
}

.centered {
  margin: 10rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

a:active,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}

.error {
  transition: all 0.5s ease-in-out;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
}
.alert {
  margin: 0 auto;
  max-width: 600px;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}
.ourwork {
  margin: 0 auto;
  max-width: 100%;
  padding-top: 50px;
  padding-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
}

.ourwork img {
  margin: 0 auto;
  border-radius: 20px;
  margin-bottom: 20px;
  height: 450px;
  width: 230px;
}
.mycrs {
  padding: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1000px) {
  .mycrs {
    padding: 20px;
    flex-wrap: wrap;
    margin: 10px auto;
  }
  #testimonials-slider-pager .testimonials-slider-pager-one .pager-item img {
    height: 200px;
    width: 200px;
  }
}
/* .ourwork img:hover {
  height: 250px;
  width: 150px;
  cursor: pointer;
  box-shadow: 4px 16px 16px 4px rgba(223, 169, 90, 0.527);
  transition: all 0.4s ease-out;
} */

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
.thm-black-bg {
  background-color: #2e3d62;
}

.thm-base-bg {
  background-color: #ff8257;
}

.thm-gray-bg {
  background-color: #f3f3f3;
}

hr.style-one {
  margin: 0;
  border-top: 1px solid #eaeaea;
}

.block-title {
  margin-bottom: 60px;
}

.block-title__text {
  margin: 0;
  font-weight: bold;
  color: #2e3d62;
  font-size: 50px;
  line-height: 60px;
}
.block-title__text span {
  position: relative;
}
.block-title__text span:before {
  content: "";
  background-color: #ff8257;
  opacity: 0.102;
  width: 100%;
  height: 8px;
  position: absolute;
  bottom: 12px;
  left: 0;
  border-radius: 4px;
}
span {
  font-size: 70px;
}
.icon-revresed {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  display: inline-block;
}

.bootstrap-select .btn-light:not(:disabled):not(.disabled).active,
.bootstrap-select .btn-light:not(:disabled):not(.disabled):active,
.bootstrap-select .show > .btn-light.dropdown-toggle {
  background-color: transparent;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none;
}
.bootstrap-select > .dropdown-toggle {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: none !important;
}

.bootstrap-select .dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  z-index: 991;
}
.bootstrap-select .dropdown-menu > li + li > a {
  border-top: 1px solid #eee;
}
.bootstrap-select .dropdown-menu > li:first-child > a {
  padding-top: 10px;
}
.bootstrap-select .dropdown-menu > li:last-child > a {
  padding-bottom: 10px;
}
.bootstrap-select .dropdown-menu > li.selected > a {
  background: #ff8257;
  color: #fff;
}
.bootstrap-select .dropdown-menu > li > a {
  font-size: 16px;
  font-weight: 500;
  padding: 9px 20px;
  color: #2e3d62;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.bootstrap-select .dropdown-menu > li > a:hover {
  background: #ff8257;
  color: #fff;
  cursor: pointer;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #fff;
  /* background-image: url(../images/preloader.gif); */
  background-repeat: no-repeat;
  background-position: center center;
}
.service-one__three {
  width: 400px;
  padding: 50px 50px;
  border-radius: 4px;
  min-height: 520px;
  background-color: white;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(46, 61, 98, 0.1);
  box-shadow: 0px 10px 60px 0px rgba(46, 61, 98, 0.1);
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.service-one__three:hover {
  box-shadow: 1px 4px 6px 6px rgba(126, 125, 124, 0.103);
  -webkit-transition: all 0.4s ease-in-out;

  transition: all 0.4s ease;
}
.scroll-to-top {
  border-radius: 50%;
  display: inline-block;
  width: 45px;
  height: 45px;
  background: #ff8257;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99;
  text-align: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  transition-delay: 0.1s;
}

.scroll-to-top:hover {
  background-color: #9364d4;
}

.PhoneInputCountry {
  border-radius: 5px;
  background-color: white;
  border: none;
  padding: 5px;
  border-bottom: 2px solid coral;
}
.scroll-to-top i {
  color: #fff;
  font-size: 18px;
  line-height: 45px;
  border-radius: 50%;
}

/*
* 3. header styles
*/
.header-navigation {
  background-color: transparent;
  margin-bottom: 0;
  border: none;
  border-radius: 0;
  padding: 0;
  position: relative;
  background-color: transparent;
}
.header-navigation .container {
  background: transparent;
  position: relative;
  display: block;
}
.header-navigation .container .logo-box {
  float: left;
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: -80px;
}
.header-navigation .container .navbar-brand {
  height: auto;
  margin: 0;
}
.header-navigation .container .menu-toggler {
  display: none;
}
.header-navigation .container .right-side-box {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 2px;
  margin-right: -80px;
}
.header-navigation .main-navigation {
  float: none;
  text-align: center;
}
@media (min-width: 1200px) {
  .header-navigation .main-navigation {
    display: block !important;
  }
}

.header-navigation ul.navigation-box {
  margin: 0;
  padding: 0;
  padding-right: 260px;
  list-style: none;
}
.header-navigation ul.navigation-box li a .sub-nav-toggler {
  display: none;
}
.header-navigation ul.navigation-box > li {
  position: relative;
  padding: 44px 0;
  display: inline-block;
  vertical-align: middle;
  /* Second Level Menu */
  /* Thrid Level Menu */
}
.header-navigation ul.navigation-box > li + li {
  margin-left: 45px;
}
.header-navigation ul.navigation-box > li:first-child {
  padding-left: 0;
}
.header-navigation ul.navigation-box > li:last-child {
  padding-right: 0;
}
.header-navigation ul.navigation-box > li > a {
  font-weight: 500;
  font-size: 18px;
  color: #666e82;
  padding: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: relative;
}
.header-navigation ul.navigation-box > li.current > a,
.header-navigation ul.navigation-box > li:hover > a {
  color: #ff8257;
}
.header-navigation ul.navigation-box > li > .sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 220px;
  padding: 0px 0px;
  text-align: left;
  list-style: none;
  background-color: #2e3d62;
  background-clip: padding-box;
  opacity: 0;
  border-radius: 0px;
  visibility: hidden;
  -webkit-transition: opacity 0.4s ease, visibility 0.4s ease;
  transition: opacity 0.4s ease, visibility 0.4s ease;
  -webkit-box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
}
@media (min-width: 1200px) {
  .header-navigation ul.navigation-box > li > .sub-menu {
    display: block !important;
  }
}
.header-navigation ul.navigation-box > li > .sub-menu.right-align {
  left: auto;
  right: 0;
}
.header-navigation ul.navigation-box > li > .sub-menu.center-align {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.header-navigation ul.navigation-box > li > .sub-menu > li {
  display: block;
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.header-navigation ul.navigation-box > li > .sub-menu > li + li {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.header-navigation ul.navigation-box > li > .sub-menu > li > a {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  padding: 12px 30px;
  display: block;
  line-height: 26px;
  white-space: nowrap;
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.header-navigation ul.navigation-box > li > .sub-menu > li:hover > a {
  color: #fff;
  background: #ff8257;
}
.header-navigation ul.navigation-box > li:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
}
.header-navigation ul.navigation-box > li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
}
.header-navigation ul.navigation-box > li > ul > li {
  /* no more nested showen */
}
.header-navigation ul.navigation-box > li > ul > li > .sub-menu {
  position: absolute;
  top: 0%;
  left: 100%;
  z-index: 1000;
  float: left;
  min-width: 220px;
  padding: 0px 0px;
  text-align: left;
  list-style: none;
  background-color: #2e3d62;
  background-clip: padding-box;
  opacity: 0;
  border-radius: 0px;
  visibility: hidden;
  -webkit-transition: opacity 0.4s ease, visibility 0.4s ease;
  transition: opacity 0.4s ease, visibility 0.4s ease;
  -webkit-box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
}
@media (min-width: 1200px) {
  .header-navigation ul.navigation-box > li > ul > li > .sub-menu {
    display: block !important;
  }
}
.header-navigation ul.navigation-box > li > ul > li > .sub-menu.right-align {
  left: auto;
  right: 100%;
}
.header-navigation ul.navigation-box > li > ul > li > .sub-menu.center-align {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.header-navigation ul.navigation-box > li > ul > li > .sub-menu > li {
  display: block;
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.header-navigation ul.navigation-box > li > ul > li > .sub-menu > li + li {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.header-navigation ul.navigation-box > li > ul > li > .sub-menu > li > a {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  padding: 12px 30px;
  display: block;
  line-height: 26px;
  white-space: nowrap;
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.header-navigation ul.navigation-box > li > ul > li > .sub-menu > li:hover > a {
  color: #fff;
  background: #ff8257;
}
.header-navigation ul.navigation-box > li > ul > li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
}
.header-navigation ul.navigation-box > li > ul > li ul {
  display: none;
}

.header-one {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}
.header-one__btn {
  display: inline-block;
  vertical-align: middle;
  background-color: #ff8257;
  color: white;
  font-size: 16px;
  font-weight: 600;
  line-height: 1em;
  padding: 19px 40px;
  margin-right: 10px;
  border-radius: 27px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(255, 130, 87, 0.3);
  box-shadow: 0px 10px 30px 0px rgba(255, 130, 87, 0.3);
}
.header-one__btn:hover {
  background-color: #2e3d62;
  color: #fff;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(46, 61, 98, 0.3);
  box-shadow: 0px 10px 30px 0px rgba(46, 61, 98, 0.3);
}

.header-one .header-navigation.stricky-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #fff;
  -webkit-box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.06);
}
.header-one .header-navigation.stricky-fixed ul.navigation-box > li {
  padding-top: 25px;
  padding-bottom: 25px;
}

.header-one__home-two .header-navigation ul.navigation-box > li > a {
  color: #fff;
  position: relative;
}
.header-one__home-two .header-navigation ul.navigation-box > li > a:before {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: right center;
  transform-origin: right center;
}
.header-one__home-two
  .header-navigation
  ul.navigation-box
  > li.current
  > a:before,
.header-one__home-two
  .header-navigation
  ul.navigation-box
  > li:hover
  > a:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}
.header-one__home-two .header-one__btn {
  color: #fff;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(255, 130, 87, 0.3);
  box-shadow: 0px 10px 30px 0px rgba(255, 130, 87, 0.3);
  background-color: #ff8257;
}
.header-one__home-two .header-navigation.stricky-fixed {
  background-color: #2e3d62;
}

/*
* 4. Umbrella Page
*/
.umbrella-H-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.umbrella-Heading {
  width: 50%;
  margin-bottom: 40px;
  text-align: center;
}
.umbrella-description {
  width: 50%;
  text-align: center;
  margin-bottom: 40px;
}
.trust-div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #d4d4d456;
  width: 100%;
  height: auto;
  min-height: 80px;
  flex-wrap: wrap;
  margin-top: 70px;
}
@media (max-width: 575px) {
  .trust-div {
    justify-content: space-between;
  }
  .umbrella-Heading {
    text-align: left;
    margin-top: 50px;
  }
  .trust-div img {
    margin-left: 20px;
  }
  .umbrella-description {
    text-align: left;
  }
  .trust-div {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .umbrella-Heading {
    width: 90%;
  }
  .umbrella-description {
    width: 90%;
  }
}
.trust-div img {
  height: 50px;
  width: 50px;
}
.trust-div-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.trust-div-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-bottom: -20px;
}
.trust-div-content p {
  margin-top: -10px;
  color: #2e3d62de;
}
.trust-div-content h5 {
  color: #2e3d62;
}

/*
* 4. banner styles
*/
.banner-one {
  background: transparent url(../images/banner-bg-1-1.png) right top no-repeat;
  background-size: cover;
  padding-top: 315px;
  padding-bottom: 147px;
  position: relative;
}
.banner-one [class*="bubble-"] {
  position: absolute;
  -webkit-animation-name: bubbleMover;
  animation-name: bubbleMover;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-perspective: 400px;
  perspective: 400px;
}
.banner-one .bubble-1 {
  top: 15%;
  left: 13%;
}
.banner-one .bubble-2 {
  top: 25%;
  left: 35%;
}
.banner-one .bubble-3 {
  top: 60%;
  left: 15%;
}
.banner-one .bubble-4 {
  bottom: 10%;
  left: 45%;
}
.banner-one .bubble-5 {
  top: 10%;
  right: 15%;
}
.banner-one .bubble-6 {
  top: 30%;
  right: 40%;
}
.banner-one__moc {
  position: absolute;
  top: 245px;

  right: 0;
}

.banner-one .block-title {
  margin-bottom: 35px;
}
.banner-one .block-title__text {
  font-size: 60px;
  line-height: 70px;
}
.banner-one__text {
  margin: 0;
  font-size: 16px;
  line-height: 34px;
  color: #666e82;
  margin-bottom: 50px;
}
.banner-one__btn {
  display: inline-block;
  vertical-align: middle;
  border-radius: 32px;
  text-align: center;
  word-break: break-all;
  background-color: #ff8257;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 49.5px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(255, 130, 87, 0.3);
  box-shadow: 0px 10px 30px 0px rgba(255, 130, 87, 0.3);
}
.banner-one__btn:hover {
  background-color: #2e3d62;
  color: #fff;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(46, 61, 98, 0.3);
  box-shadow: 0px 10px 30px 0px rgba(46, 61, 98, 0.3);
}
@media (max-width: 440px) {
  .hero-flex {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
.banner-one__btn__hero {
  display: inline-block;
  vertical-align: middle;
  border-radius: 32px;
  text-align: center;
  word-break: break-all;
  background-color: #ff8257;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 38.5px;
  margin-right: 8px;

  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(255, 130, 87, 0.3);
  box-shadow: 0px 10px 30px 0px rgba(255, 130, 87, 0.3);
}
.banner-one__btn__hero:hover {
  background-color: #2e3d62;
  color: #fff;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(46, 61, 98, 0.3);
  box-shadow: 0px 10px 30px 0px rgba(46, 61, 98, 0.3);
}

.banner-one__home-two {
  background-image: url(../images/banner-bg-1-2.jpg);
}
.banner-one__home-two .block-title__text {
  color: #fff;
}
.banner-one__home-two .block-title__text span:before {
  background-color: #fff;
  opacity: 0.1;
}
.banner-one__home-two .banner-one__text {
  color: #fff;
}
.banner-one__home-two .banner-one__btn {
  background-color: #fff;
  color: #2e3d62;
}
.banner-one__home-two .banner-one__btn:hover {
  background-color: #2e3d62;
  color: #fff;
}
.banner-one__home-two .banner-one__moc {
  top: auto;
  bottom: 0;
}
.service-content {
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 20px 0px;
  border-radius: 20px;
}
.active {
  color: coral;
}
.service-content h1 {
  margin: 0;
  font-weight: bold;
  text-align: left;
  color: #2e3d62;
  font-size: 50px;
  line-height: 60px;
}

.service-content p {
  text-align: left;
  padding: 1% 0;
}

/*
* 5. footer styles
*/
.site-footer {
  position: relative;
}
.site-footer [class*="bubble-"] {
  position: absolute;
  -webkit-animation-name: bubbleMover;
  animation-name: bubbleMover;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-perspective: 400px;
  perspective: 400px;
}
.site-footer .bubble-1 {
  top: 15%;
  left: 13%;
}
.site-footer .bubble-2 {
  top: 15%;
  right: 20%;
}
.site-footer .bubble-3 {
  bottom: 20%;
  left: 10%;
}
.site-footer .bubble-4 {
  bottom: 15%;
  right: 13%;
}

@-webkit-keyframes bubbleMover {
  0% {
    -webkit-transform: rotate3d(0, 1, 0, 15deg) translateY(0px) translateX(0px);
    transform: rotate3d(0, 1, 0, 15deg) translateY(0px) translateX(0px);
  }
  30% {
    -webkit-transform: rotate3d(0, 0, 1, 20deg) translateY(10px)
      translateX(10px);
    transform: rotate3d(0, 0, 1, 20deg) translateY(10px) translateX(10px);
  }
  60% {
    -webkit-transform: rotate3d(1, 0, 0, 15deg) translateY(20px)
      translateX(20px);
    transform: rotate3d(1, 0, 0, 15deg) translateY(20px) translateX(20px);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 20deg) translateY(10px)
      translateX(10px);
    transform: rotate3d(0, 0, 1, 20deg) translateY(10px) translateX(10px);
  }
  100% {
    -webkit-transform: rotate3d(0, 1, 0, 15deg) translateY(0px) translateX(0px);
    transform: rotate3d(0, 1, 0, 15deg) translateY(0px) translateX(0px);
  }
}

@keyframes bubbleMover {
  0% {
    -webkit-transform: rotate3d(0, 1, 0, 15deg) translateY(0px) translateX(0px);
    transform: rotate3d(0, 1, 0, 15deg) translateY(0px) translateX(0px);
  }
  30% {
    -webkit-transform: rotate3d(0, 0, 1, 20deg) translateY(10px)
      translateX(10px);
    transform: rotate3d(0, 0, 1, 20deg) translateY(10px) translateX(10px);
  }
  60% {
    -webkit-transform: rotate3d(1, 0, 0, 15deg) translateY(20px)
      translateX(20px);
    transform: rotate3d(1, 0, 0, 15deg) translateY(20px) translateX(20px);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 20deg) translateY(10px)
      translateX(10px);
    transform: rotate3d(0, 0, 1, 20deg) translateY(10px) translateX(10px);
  }
  100% {
    -webkit-transform: rotate3d(0, 1, 0, 15deg) translateY(0px) translateX(0px);
    transform: rotate3d(0, 1, 0, 15deg) translateY(0px) translateX(0px);
  }
}
.site-footer__main-footer {
  padding-bottom: 120px;
}

.site-footer__bottom-footer {
  background-color: #fafafa;
  padding: 44px 0;
}
.site-footer__bottom-footer p {
  margin: 0;
  color: #666e82;
  font-size: 16px;
}
.site-footer__bottom-footer p a {
  color: #ff8257;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.site-footer__bottom-footer p a:hover {
  color: #9364d4;
}

.footer-widget p {
  margin: 0;
  color: #666e82;
  font-size: 15px;
  line-height: 26px;
}
.footer-widget p + p {
  margin-top: 16px;
}
.footer-widget .social-block {
  margin-top: -20px;
}
.footer-widget .social-block a {
  display: inline-block;
  vertical-align: middle;
  color: #666e82;
  font-size: 22px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.footer-widget .social-block a + a {
  margin-left: 22px;
}
.footer-widget .social-block a:hover {
  color: #ff8257;
}

.footer-widget__footer-logo {
  display: inline-block;
  vertical-align: middle;
  margin-top: -8px;
  margin-bottom: 28px;
}

.footer-widget__title {
  margin: 0;
  color: #2e3d62;
  font-size: 18px;
  line-height: 1em;
  font-weight: 600;
  margin-bottom: 35px;
}

.footer-widget__link-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer-widget__link-list li {
  line-height: 1em;
}
.footer-widget__link-list li + li {
  margin-top: 24px;
}
.footer-widget__link-list li a {
  color: #666e82;
  font-size: 15px;
  display: inline-block;
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.footer-widget__link-list li a:before {
  content: "\f101";
  font-family: "FontAwesome";
  color: #ff8257;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%) translateX(-15px);
  transform: translateY(-50%) translateX(-15px);
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.footer-widget__link-list li a:hover {
  color: #9364d4;
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
}
.footer-widget__link-list li a:hover:before {
  opacity: 1;
}

.site-footer__subscribe {
  padding-bottom: 125px;
  padding-top: 110px;
}

.site-footer__subscribe-form {
  width: 100%;
  max-width: 685px;
  margin-left: auto;
  margin-right: auto;
  height: 84px;
  border-style: solid;
  border-width: 1px;
  border-color: #eaeaea;
  border-radius: 42px;
  position: relative;
  overflow: hidden;
}
.site-footer__subscribe-form input {
  border: none;
  outline: none;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #666e82;
  font-size: 16px;
  padding-left: 60px;
}
.site-footer__subscribe-form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #666e82;
}
.site-footer__subscribe-form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #666e82;
}
.site-footer__subscribe-form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #666e82;
}
.site-footer__subscribe-form input:-moz-placeholder {
  /* Firefox 18- */
  color: #666e82;
}
.site-footer__subscribe-form button[type="submit"] {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #ff8257;
  width: 211px;
  height: 64px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
  border-radius: 32px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.site-footer__subscribe-form button[type="submit"]:hover {
  background-color: #9364d4;
}

/*
* 6. blog styles
*/
.blog-one {
  padding-top: 190px;
  padding-bottom: 90px;
  background: transparent url(../images/blog-bg-1-1.png) center center no-repeat;
  background-size: cover;
}

.blog-one__single {
  border-radius: 4px;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(46, 61, 98, 0.1);
  box-shadow: 0px 10px 60px 0px rgba(46, 61, 98, 0.1);
  margin-bottom: 30px;
}

.blog-one__single-inner-block {
  border-radius: 4px;
  background-color: white;
  overflow: hidden;
  position: relative;
  padding-top: 85px;
  padding-bottom: 55px;
  padding-left: 60px;
  padding-right: 60px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.blog-one__single-inner-block:before,
.blog-one__single-inner-block:after {
  content: "";
  width: 191px;
  height: 191px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.1;
  position: absolute;
  -webkit-transition: all 0.4s ease 0.2s;
  transition: all 0.4s ease 0.2s;
}
.blog-one__single-inner-block:before {
  top: 0;
  right: 0;
  -webkit-transform: translate(60%, -60%);
  transform: translate(60%, -60%);
}
.blog-one__single-inner-block:after {
  bottom: 0;
  left: 0;
  -webkit-transform: translate(-60%, 60%);
  transform: translate(-60%, 60%);
}

.blog-one__date {
  position: relative;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px 0;
  background-color: #9364d4;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  padding: 0 24.5px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.blog-one__author {
  position: relative;
  z-index: 10;
  color: #9364d4;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.2em;
  font-weight: 600;
  display: inline-block;
  line-height: 1em;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.blog-one__title {
  position: relative;
  z-index: 10;
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  margin-bottom: 35px;
}
.blog-one__title a {
  color: #2e3d62;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.blog-one__text {
  position: relative;
  z-index: 10;
  margin: 0;
  color: #666e82;
  font-size: 16px;
  line-height: 34px;
  margin-bottom: 20px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.blog-one__link {
  position: relative;
  z-index: 10;
  display: inline-block;
  vertical-align: middle;
  font-size: 32px;
  color: #bcbfc7;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.blog-one__single-inner-block:hover {
  background-color: #9364d4;
}
.blog-one__single-inner-block:hover .blog-one__date {
  background-color: #fff;
  color: #9364d4;
}
.blog-one__single-inner-block:hover .blog-one__title a,
.blog-one__single-inner-block:hover .blog-one__text,
.blog-one__single-inner-block:hover .blog-one__link,
.blog-one__single-inner-block:hover .blog-one__author {
  color: #fff;
}
.blog-one__single-inner-block:hover:before {
  top: 0;
  right: 0;
  -webkit-transform: translate(30%, -30%);
  transform: translate(30%, -30%);
}
.blog-one__single-inner-block:hover:after {
  bottom: 0;
  left: 0;
  -webkit-transform: translate(-30%, 30%);
  transform: translate(-30%, 30%);
}

.blog-one [class*="col-"]:nth-child(2) .blog-one__date {
  background-color: #ef4059;
}
.blog-one [class*="col-"]:nth-child(2) .blog-one__author {
  color: #ef4059;
}
.blog-one [class*="col-"]:nth-child(2) .blog-one__single-inner-block:hover {
  background-color: #ef4059;
}
.blog-one
  [class*="col-"]:nth-child(2)
  .blog-one__single-inner-block:hover
  .blog-one__date {
  background-color: #fff;
  color: #ef4059;
}
.blog-one
  [class*="col-"]:nth-child(2)
  .blog-one__single-inner-block:hover
  .blog-one__title
  a,
.blog-one
  [class*="col-"]:nth-child(2)
  .blog-one__single-inner-block:hover
  .blog-one__text,
.blog-one
  [class*="col-"]:nth-child(2)
  .blog-one__single-inner-block:hover
  .blog-one__link,
.blog-one
  [class*="col-"]:nth-child(2)
  .blog-one__single-inner-block:hover
  .blog-one__author {
  color: #fff;
}

.blog-one [class*="col-"]:nth-child(3) .blog-one__date {
  background-color: #5dd8d3;
}
.blog-one [class*="col-"]:nth-child(3) .blog-one__author {
  color: #5dd8d3;
}
.blog-one [class*="col-"]:nth-child(3) .blog-one__single-inner-block:hover {
  background-color: #5dd8d3;
}
.blog-one
  [class*="col-"]:nth-child(3)
  .blog-one__single-inner-block:hover
  .blog-one__date {
  background-color: #fff;
  color: #5dd8d3;
}
.blog-one
  [class*="col-"]:nth-child(3)
  .blog-one__single-inner-block:hover
  .blog-one__title
  a,
.blog-one
  [class*="col-"]:nth-child(3)
  .blog-one__single-inner-block:hover
  .blog-one__text,
.blog-one
  [class*="col-"]:nth-child(3)
  .blog-one__single-inner-block:hover
  .blog-one__link,
.blog-one
  [class*="col-"]:nth-child(3)
  .blog-one__single-inner-block:hover
  .blog-one__author {
  color: #fff;
}

.blog-one [class*="col-"]:nth-child(4) .blog-one__date {
  background-color: #7e9ae0;
}
.blog-one [class*="col-"]:nth-child(4) .blog-one__author {
  color: #7e9ae0;
}
.blog-one [class*="col-"]:nth-child(4) .blog-one__single-inner-block:hover {
  background-color: #7e9ae0;
}
.blog-one
  [class*="col-"]:nth-child(4)
  .blog-one__single-inner-block:hover
  .blog-one__date {
  background-color: #fff;
  color: #7e9ae0;
}
.blog-one
  [class*="col-"]:nth-child(4)
  .blog-one__single-inner-block:hover
  .blog-one__title
  a,
.blog-one
  [class*="col-"]:nth-child(4)
  .blog-one__single-inner-block:hover
  .blog-one__text,
.blog-one
  [class*="col-"]:nth-child(4)
  .blog-one__single-inner-block:hover
  .blog-one__link,
.blog-one
  [class*="col-"]:nth-child(4)
  .blog-one__single-inner-block:hover
  .blog-one__author {
  color: #fff;
}

.blog-one [class*="col-"]:nth-child(5) .blog-one__date {
  background-color: #ed539b;
}
.blog-one [class*="col-"]:nth-child(5) .blog-one__author {
  color: #ed539b;
}
.blog-one [class*="col-"]:nth-child(5) .blog-one__single-inner-block:hover {
  background-color: #ed539b;
}
.blog-one
  [class*="col-"]:nth-child(5)
  .blog-one__single-inner-block:hover
  .blog-one__date {
  background-color: #fff;
  color: #ed539b;
}
.blog-one
  [class*="col-"]:nth-child(5)
  .blog-one__single-inner-block:hover
  .blog-one__title
  a,
.blog-one
  [class*="col-"]:nth-child(5)
  .blog-one__single-inner-block:hover
  .blog-one__text,
.blog-one
  [class*="col-"]:nth-child(5)
  .blog-one__single-inner-block:hover
  .blog-one__link,
.blog-one
  [class*="col-"]:nth-child(5)
  .blog-one__single-inner-block:hover
  .blog-one__author {
  color: #fff;
}

.blog-one [class*="col-"]:nth-child(6) .blog-one__date {
  background-color: #fd8257;
}
.blog-one [class*="col-"]:nth-child(6) .blog-one__author {
  color: #fd8257;
}
.blog-one [class*="col-"]:nth-child(6) .blog-one__single-inner-block:hover {
  background-color: #fd8257;
}
.blog-one
  [class*="col-"]:nth-child(6)
  .blog-one__single-inner-block:hover
  .blog-one__date {
  background-color: #fff;
  color: #fd8257;
}
.blog-one
  [class*="col-"]:nth-child(6)
  .blog-one__single-inner-block:hover
  .blog-one__title
  a,
.blog-one
  [class*="col-"]:nth-child(6)
  .blog-one__single-inner-block:hover
  .blog-one__text,
.blog-one
  [class*="col-"]:nth-child(6)
  .blog-one__single-inner-block:hover
  .blog-one__link,
.blog-one
  [class*="col-"]:nth-child(6)
  .blog-one__single-inner-block:hover
  .blog-one__author {
  color: #fff;
}

.blog-one__blog-page {
  background-color: #fff;
  background-image: none;
  padding-top: 120px;
  border-bottom: 1px solid #eaeaea;
}

.blog-post-pagination {
  margin-top: 30px;
  margin-bottom: 30px;
}
.blog-post-pagination a {
  display: inline-block;
  vertical-align: middle;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #f3f3f3;
  line-height: 52px;
  text-align: center;
  font-size: 18px;
  color: #2e3d62;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  font-weight: 600;
}
.blog-post-pagination a.prev,
.blog-post-pagination a.next {
  background: #2e3d62;
  color: #fff;
  font-size: 14px;
}
.blog-post-pagination a.active {
  cursor: auto;
}
.blog-post-pagination a.active,
.blog-post-pagination a:hover {
  background: #ff8257;
  color: #2e3d62;
}
.blog-post-pagination a + a {
  margin-left: 10px;
}

.blog-details {
  padding: 120px 0;
  border-bottom: 1px solid #eaeaea;
}
.blog-details__image {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.blog-details__image > img {
  width: 100%;
}
.blog-details__date {
  background-color: #ef4059;
  display: inline-block;
  vertical-align: middle;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  line-height: 1em;
  padding: 11px 24.5px;
  position: absolute;
  top: 0;
  left: 0;
}
.blog-details__meta {
  margin-top: 15px;
}
.blog-details__meta-link {
  position: relative;
  z-index: 10;
  color: #ef4059;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.2em;
  font-weight: 600;
  display: inline-block;
  line-height: 1em;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.blog-details__meta-sep {
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px;
  margin-right: 14px;
}
.blog-details__title {
  margin: 0;
  font-size: 30px;
  font-weight: 600;
  color: #2e3d62;
  margin-bottom: 40px;
}
.blog-details__text {
  margin: 0;
  font-size: 16px;
  line-height: 34px;
  color: #666e82;
}
.blog-details__text + .blog-details__text {
  margin-top: 35px;
}
.blog-details__content-title {
  margin: 0;
  color: #2e3d62;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 40px;
}
.blog-details__author {
  border-radius: 4px;
  background-color: white;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(46, 61, 98, 0.1);
  box-shadow: 0px 10px 60px 0px rgba(46, 61, 98, 0.1);
  padding: 60px;
  margin-top: 60px;
  margin-bottom: 60px;
}
.blog-details__author-image,
.blog-details__author-content {
  display: table-cell;
  vertical-align: top;
}
.blog-details__author-image {
  width: 169px;
  border-radius: 4px;
  overflow: hidden;
}
.blog-details__author-image > img {
  width: 100%;
}
.blog-details__author-content {
  padding-left: 40px;
}
.blog-details__author-content h3,
.blog-details__author-content p {
  margin: 0;
}
.blog-details__author-content h3 {
  color: #2e3d62;
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: 600;
}
.blog-details__author-content p {
  font-size: 16px;
  line-height: 34px;
  color: #666e82;
}

.comment-one {
  margin-bottom: 60px;
}
.comment-one__single {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 50px;
}
.comment-one__single + .comment-one__single {
  margin-top: 60px;
}
.comment-one__image,
.comment-one__content {
  display: table-cell;
  vertical-align: top;
}
.comment-one__image {
  width: 90px;
}
.comment-one__image .inner-block {
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
}
.comment-one__image .inner-block > img {
  width: 100%;
}
.comment-one__content {
  padding-left: 40px;
}
.comment-one__author {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #2e3d62;
}
.comment-one__date {
  color: #ff8257;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.2em;
  line-height: 1em;
  margin: 0;
  margin-top: 15px;
  margin-bottom: 25px;
}
.comment-one__date-sep {
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px;
  margin-right: 12px;
}
.comment-one__text {
  color: #666e82;
  font-size: 16px;
  line-height: 34px;
  margin: 0;
}
.comment-one__reply {
  display: inline-block;
  vertical-align: middle;
  border-radius: 22px;
  background-color: #ff8257;
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding: 5px 30px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.comment-one__reply:hover {
  background-color: #2e3d62;
  color: #fff;
}
.comment-one__top-left,
.comment-one__top-right {
  display: table-cell;
  vertical-align: top;
}

.reply-form input,
.reply-form textarea {
  border: none;
  outline: none;
  width: 100%;
  display: block;
  border-radius: 32px;
  height: 64px;
  border: 1px solid #eaeaea;
  color: #666e82;
  font-size: 16px;
  padding-left: 30px;
  margin-bottom: 30px;
}
.reply-form input::-webkit-input-placeholder,
.reply-form textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #666e82;
}
.reply-form input::-moz-placeholder,
.reply-form textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #666e82;
}
.reply-form input:-ms-input-placeholder,
.reply-form textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #666e82;
}
.reply-form input:-moz-placeholder,
.reply-form textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #666e82;
}
.reply-form textarea {
  height: 243px;
  padding-top: 20px;
}
.reply-form__btn {
  display: inline-block;
  vertical-align: middle;
  border: none;
  outline: none !important;
  cursor: pointer;
  background-color: #ff8257;
  border-radius: 33.5px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding: 15px 60px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.reply-form__btn:hover {
  background-color: #2e3d62;
}

/*
* 7. fun fact styles
*/
.fun-fact-one {
  position: relative;
  padding-top: 110px;
  padding-bottom: 120px;
}
.fun-fact-one [class*="bubble-"] {
  position: absolute;
  -webkit-animation-name: bubbleMover;
  animation-name: bubbleMover;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-perspective: 400px;
  perspective: 400px;
}
.fun-fact-one .bubble-1 {
  top: 25%;
  left: 23%;
}
.fun-fact-one .bubble-2 {
  top: 15%;
  right: 30%;
}
.fun-fact-one .bubble-3 {
  bottom: 20%;
  left: 10%;
}
.fun-fact-one .bubble-4 {
  bottom: 15%;
  right: 13%;
}
.fun-fact-one__box-wrapper {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.fun-fact-one__single .inner-block {
  border-radius: 50%;
  background-color: white;
  -webkit-box-shadow: 0px 25px 60px 0px rgba(46, 61, 98, 0.05);
  box-shadow: 0px 25px 60px 0px rgba(46, 61, 98, 0.05);
  width: 220px;
  height: 220px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.fun-fact-one__single .inner-block:before {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border: 1px solid #ef4059;
  border-radius: 50%;
}
.fun-fact-one__single:nth-child(2n) {
  margin-top: 80px;
}
.fun-fact-one__title {
  margin: 0;
  color: #ef4059;
  font-size: 40px;
  font-weight: 300;
  line-height: 1em;
}
.fun-fact-one__text {
  margin: 0;
  font-size: 12px;
  line-height: 1em;
  color: #666e82;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin-top: 20px;
}
.fun-fact-one__single:nth-child(2) .inner-block:before {
  border-color: #5dd8d3;
}
.fun-fact-one__single:nth-child(2) .inner-block .fun-fact-one__title {
  color: #5dd8d3;
}
.fun-fact-one__single:nth-child(3) .inner-block:before {
  border-color: #9364d4;
}
.fun-fact-one__single:nth-child(3) .inner-block .fun-fact-one__title {
  color: #9364d4;
}
.fun-fact-one__single:nth-child(4) .inner-block:before {
  border-color: #ff8257;
}
.fun-fact-one__single:nth-child(4) .inner-block .fun-fact-one__title {
  color: #ff8257;
}

/*
* 8. brand styles
*/
.brand-style-one {
  position: relative;
}
.brand-style-one [class*="bubble-"] {
  position: absolute;
  -webkit-animation-name: bubbleMover;
  animation-name: bubbleMover;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-perspective: 400px;
  perspective: 400px;
}
.brand-style-one .bubble-1 {
  top: 15%;
  left: 18%;
}
.brand-style-one .bubble-2 {
  top: 30%;
  right: 20%;
}

.brand-carousel-one {
  padding: 119.5px 0;
  padding-left: 45px;
  padding-right: 45px;
}
.brand-carousel-one .item img {
  width: auto;
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.3;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.brand-carousel-one .item img:hover {
  opacity: 0.6;
}
.brand-carousel-one .owl-dots,
.brand-carousel-one .owl-nav {
  display: none;
}

/*
* 9. testimonials styles
*/
.testimonials-style-one {
  background-color: #f6fffa;
  padding-top: 150px;
  padding-bottom: 150px;
  background-size: cover;
  position: relative;
}
.testimonials-style-one .block-title {
  margin-bottom: 45px;
}
.brandsize {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 150px;
}
.brandsize img {
  flex-shrink: 0;
  width: 65%;
  height: 65%;
  object-fit: cover;
}
.brandsize img:hover {
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  transform: scale(1.1);
}
.testimonials-style-one .single-testi-one p {
  margin: 0;
  color: #666e82;
  font-size: 22px;
  line-height: 46px;
}
.testimonials-style-one .single-testi-one h3 {
  color: #ff8257;
  font-weight: 600;
  font-size: 20px;
  margin: 0;
  margin-top: 40px;
}
.testimonials-style-one .bx-wrapper {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  margin-bottom: 0;
}
.testimonials-style-one .bx-wrapper ul.slider {
  padding: 0;
  margin: 0;
}
.testimonials-style-one .bx-wrapper .bx-controls-direction {
  position: absolute;
  bottom: -10px;
  right: 0;
}
.testimonials-style-one .bx-wrapper .bx-controls-direction a {
  text-indent: 0;
  background-image: none;
  font-size: 32px;
  position: relative;
  top: 0px;
  opacity: 0.3;
  color: #666e82;
  z-index: 10;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.testimonials-style-one .bx-wrapper .bx-controls-direction a:hover {
  opacity: 1;
  color: #ff8257;
}
.testimonials-style-one .bx-wrapper .bx-prev {
  left: 0px;
  margin-right: 25px;
}
.testimonials-style-one .bx-wrapper .bx-next {
  right: 0px;
}

#testimonials-slider-pager {
  width: 536px;
  height: 536px;
  position: relative;
  margin-top: -80px;
  -webkit-animation-name: tesiMove;
  animation-name: tesiMove;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
@-webkit-keyframes tesiMove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  15% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  33% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  66% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes tesiMove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  15% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  33% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  66% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
#testimonials-slider-pager .testi-icon {
  position: absolute;
  top: 50%;
  left: 8%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  width: 99px;
  height: 99px;
  border-radius: 50%;
  background-color: #ff8257;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 10;
}
.btn-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
#testimonials-slider-pager .testi-icon:after,
#testimonials-slider-pager .testi-icon:before {
  width: 99px;
  height: 99px;
  border-radius: 50%;
  background-color: #ff8257;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
#testimonials-slider-pager .testi-icon:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 40px rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 40px rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
#testimonials-slider-pager .testi-icon img {
  position: relative;
  z-index: 11;
}
#testimonials-slider-pager .testimonials-slider-pager-one .pager-item {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
#testimonials-slider-pager .testimonials-slider-pager-one .pager-item.active {
  opacity: 1;

  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  transition: all 0.4s ease;
}
#testimonials-slider-pager .testimonials-slider-pager-one .pager-item img {
  flex-shrink: 0;
  object-fit: cover;
  height: 450px;
  width: 430px;
  border-radius: 5000px 10500px 7500px 5000px;
}
#testimonials-slider-pager
  .testimonials-slider-pager-one
  .pager-item
  img:hover {
  transform: scale(1.05);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.projects {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tdiv {
  height: 460px;
  width: 440px;
  background-color: #d7f7e593;
  border-radius: 5000px 10500px 6500px 5000px;
  box-shadow: 1px 4px 20px 10px #75ca9a3b;

  padding-top: -50px;
}
@media (max-width: 1000px) {
  .tdiv {
    height: 360px;

    background-color: transparent;
    box-shadow: none !important;
  }
}

.inner-banner {
  background-size: cover;
  padding-bottom: 120px;
  padding-top: 188px;
}
.inner-banner__title {
  margin: 0;
  color: #fff;
  font-weight: 700;
  font-size: 46px;
}
.inner-banner__title span {
  position: relative;
}
.inner-banner__title span:before {
  content: "";
  background-color: white;
  opacity: 0.102;
  width: 100%;
  height: 8px;
  position: absolute;
  bottom: 12px;
  left: 0;
  border-radius: 4px;
}
.inner-banner .container {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.thm-breadcrumb {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
.thm-breadcrumb:before {
  content: "";
  background-color: white;
  opacity: 0.102;
  width: 100%;
  height: 8px;
  position: absolute;
  bottom: 8px;
  left: 0;
  border-radius: 4px;
}

.thm-breadcrumb__item a,
.thm-breadcrumb__item span {
  font-size: 18px;
  color: #fcfbfe;
}

.thm-breadcrumb__item + .thm-breadcrumb__item:before {
  content: ".";
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  color: #fff;
  margin-left: 10px;
  margin-right: 10px;
}

/*
* 11. pricing styles
*/
.pricing-one {
  position: relative;
  z-index: 10;
  padding-top: 60px;
}
.pricing-one__single {
  padding-bottom: 50px;
  text-align: center;
  margin-bottom: 50px;
  border-radius: 4px;
  background-color: white;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(46, 61, 98, 0.1);
  box-shadow: 0px 10px 60px 0px rgba(46, 61, 98, 0.1);
  border-top: 5px solid #9364d4;
}
.pricing-one__top {
  border-bottom: 1px solid #e8e8e8;
  padding-top: 50px;
}
.pricing-one__title {
  margin: 0;
  color: #9364d4;
  font-size: 60px;
  font-weight: 300;
  line-height: 1em;
}
.pricing-one__pack {
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 1em;
  letter-spacing: 0.2em;
  margin-top: 20px;
  margin-bottom: 60px;
  color: #2e3d62;
}
.pricing-one__feature {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 60px;
  margin-bottom: 60px;
}
.pricing-one__feature-item {
  font-size: 16px;
  color: #666e82;
  line-height: 1em;
}
.pricing-one__feature-item + .pricing-one__feature-item {
  margin-top: 20px;
}
.pricing-one__btn {
  display: inline-block;
  vertical-align: middle;
  border-radius: 27px;
  border: 1px solid #eaeaea;
  padding: 9px 49px;
  background-color: #fff;
  color: #666e82;
  font-size: 16px;
  font-weight: 600;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.pricing-one__btn:hover {
  border-color: #9364d4;
  background-color: #9364d4;
  color: #fff;
}
.pricing-one__note {
  text-transform: uppercase;
  color: #666e82;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.2em;
  margin-top: 25px;
}

[class*="col-"]:nth-child(2) .pricing-one__single {
  border-top-color: #ee539b;
}
[class*="col-"]:nth-child(2) .pricing-one__title {
  color: #ee539b;
}
[class*="col-"]:nth-child(2) .pricing-one__btn:hover {
  background-color: #ee539b;
  border-color: #ee539b;
}

[class*="col-"]:nth-child(3) .pricing-one__single {
  border-top-color: #fddd6e;
}
[class*="col-"]:nth-child(3) .pricing-one__title {
  color: #fddd6e;
}
[class*="col-"]:nth-child(3) .pricing-one__btn:hover {
  background-color: #fddd6e;
  border-color: #fddd6e;
}

/*
* 12. cta styles
*/
.cta-one {
  background: transparent url(../images/cta-bg-1-1.png) center center no-repeat;
  background-size: cover;
  padding-top: 205px;
  padding-bottom: 195px;
}
.cta-one__image-wrap {
  padding-right: 70px;
}
.cta-one__image {
  overflow: hidden;
  border-radius: 4px;
}
.cta-one__image > img {
  width: 100%;
}
.cta-one__color-box {
  position: relative;
  min-height: 276px;
}
.cta-one__color-box:before {
  content: "";
  border-radius: 4px;
  background-color: #5dd8d3;
  width: 100px;
  height: 106px;
  position: absolute;
}
.cta-one__color-box:after {
  content: "";
  border-radius: 4px;
  background-color: #fddd6e;
  width: 60px;
  height: 66px;
  position: absolute;
}
.cta-one__color-box-2:before {
  top: 0;
  right: 0;
  width: 60px;
  height: 66px;
  background-color: #ef4059;
}
.cta-one__color-box-2:after {
  bottom: 30px;
  left: 30px;
  width: 100px;
  height: 106px;
  background-color: #9364d4;
}
.cta-one__color-box-1:before {
  top: 30px;
  right: 30px;
}
.cta-one__color-box-1:after {
  bottom: 0;
  left: 0;
}
.cta-one .block-title {
  margin-bottom: 40px;
}
.cta-one__text {
  font-size: 20px;
  color: #666e82;
  line-height: 40px;
  margin: 0;
}
.cta-one__list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 45px;
}
.service-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
}

.cta-one__list-item {
  font-size: 18px;
  position: relative;
  color: #666e82;
  padding-left: 30px;
}
.cta-one__list-item i {
  color: #ff8257;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.cta-one__list-item + .cta-one__list-item {
  margin-top: 5px;
}
.cta-one__btn {
  display: inline-block;
  vertical-align: middle;
  border-radius: 32px;
  text-align: center;
  background-color: #ff8257;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 59.5px;
  margin-top: 55px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(255, 130, 87, 0.3);
  box-shadow: 0px 10px 30px 0px rgba(255, 130, 87, 0.3);
}
.cta-one__btn:hover {
  background-color: #2e3d62;
  color: #fff;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(46, 61, 98, 0.3);
  box-shadow: 0px 10px 30px 0px rgba(46, 61, 98, 0.3);
}

.cta-two {
  position: relative;
  padding-bottom: 120px;
}
.cta-two [class*="bubble-"] {
  position: absolute;
  -webkit-animation-name: bubbleMover;
  animation-name: bubbleMover;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-perspective: 400px;
  perspective: 400px;
}
.cta-two .bubble-1 {
  top: 15%;
  left: 10%;
}
.cta-two .bubble-2 {
  bottom: 15%;
  left: 40%;
}
.cta-two .bubble-3 {
  top: 5%;
  right: 10%;
}
.cta-two hr {
  margin: 0;
  border: none;
  border-top: 1px solid #eaeaea;
  margin-bottom: 120px;
}
.cta-two .block-title {
  margin-bottom: 50px;
}
.cta-two__moc {
  position: absolute;
  bottom: 70px;
  right: -33px;
}
@media (max-width: 1440px) {
  .cta-two__moc {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}
.cta-two__text {
  margin: 0;
  color: #777b92;
  font-size: 18px;
  line-height: 32px;
}
.cta-two__feature {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 50px;
}
.cta-two__feature-icon {
  font-size: 64px;
  color: #ff8257;
}
.cta-two__feature-single:nth-child(2) .cta-two__feature-icon {
  color: #5dd8d3;
}
.cta-two__feature-single:nth-child(3) .cta-two__feature-icon {
  color: #9364d4;
}
.cta-two__feature-title {
  margin: 0;
  font-size: 20px;
  margin-top: 10px;
  font-weight: 600;
}
.cta-two__feature-title a {
  color: #2e3d62;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.cta-two__feature-title a:hover {
  color: #ff8257;
}
.cta-two__btn {
  display: inline-block;
  vertical-align: middle;
  border-radius: 32px;
  text-align: center;
  background-color: #ff8257;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 59.5px;
  margin-top: 50px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(255, 130, 87, 0.3);
  box-shadow: 0px 10px 30px 0px rgba(255, 130, 87, 0.3);
}
.cta-two__btn:hover {
  background-color: #2e3d62;
  color: #fff;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(46, 61, 98, 0.3);
  box-shadow: 0px 10px 30px 0px rgba(46, 61, 98, 0.3);
}

.cta-three {
  background: transparent url(../images/cta-bg-1-2.png) bottom center no-repeat;
  background-size: cover;
  padding-bottom: 220px;
  padding-top: 382px;
  position: relative;
}
.cta-three [class*="bubble-"] {
  position: absolute;
  -webkit-animation-name: bubbleMover;
  animation-name: bubbleMover;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-perspective: 400px;
  perspective: 400px;
}
.cta-three .bubble-1 {
  top: 30%;
  left: 15%;
}
.cta-three .bubble-2 {
  top: 30%;
  right: 10%;
}
.cta-three .bubble-3 {
  bottom: 10%;
  left: 40%;
}
.cta-three .container {
  position: relative;
}
.cta-three__moc {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-100px);
  transform: translateY(-50%) translateX(-100px);
  left: 0;
}
.cta-three .block-title {
  margin-bottom: 40px;
}
.cta-three__text {
  font-size: 20px;
  color: #666e82;
  line-height: 40px;
  margin: 0;
}
.cta-three__list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 45px;
}
.cta-three__list-item {
  font-size: 18px;
  position: relative;
  color: #666e82;
  padding-left: 30px;
}
.cta-three__list-item i {
  color: #ff8257;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.cta-three__list-item + .cta-three__list-item {
  margin-top: 5px;
}
.cta-three__btn {
  display: inline-block;
  vertical-align: middle;
  border-radius: 32px;
  text-align: center;
  background-color: #ff8257;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  padding: 15px 59.5px;
  margin-top: 55px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(255, 130, 87, 0.3);
  box-shadow: 0px 10px 30px 0px rgba(255, 130, 87, 0.3);
}
.cta-three__btn:hover {
  background-color: #2e3d62;
  color: #fff;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(46, 61, 98, 0.3);
  box-shadow: 0px 10px 30px 0px rgba(46, 61, 98, 0.3);
}

/*
* 13. services styles
*/
.service-one {
  padding-top: 120px;
}
.height {
  height: 250px !important ;
  width: 250px !important ;
  padding: 30px 30px !important;
  margin-bottom: 30px;
}
.service-one__single {
  padding: 50px 50px;
  text-align: left;
  border-radius: 4px;
  background-color: white;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(46, 61, 98, 0.1);
  box-shadow: 0px 10px 60px 0px rgba(46, 61, 98, 0.1);
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.link-pages {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

@media (min-width: 1000px) {
  .al {
    padding-right: 220px;
    text-align: justify;
  }
}
@media (min-width: 2000px) {
  .al {
    padding-right: 20px;
    text-align: justify;
  }
}

.tech {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  flex-wrap: wrap;
  margin: 0 40px;
  padding: 40px 40px;
}
.tech img {
  flex-shrink: 0;
  width: 65%;
  height: 65%;
  object-fit: cover;
  -webkit-animation-name: tesiMove;
  animation-name: tesiMove;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.tech-div {
  border: 2px solid black;
  height: 300px;
  width: 100px;
}
.tech-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17%;
  width: 17%;
  min-height: 230px;
  padding: 10px;
  border-radius: 5000px 10500px 7500px 5000px;
  box-shadow: 8px 10px 10px 4px rgba(137, 173, 80, 0.322);
}
.tech-circle:hover {
  transform: scale(0.9);
  box-shadow: 8px 1px 10px 4px rgba(137, 173, 80, 0.322);

  transition: 0.4s ease-in-out;
}
.service-one__single:before,
.service-one__single:after {
  content: "";
  width: 191px;
  height: 191px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.1;
  position: absolute;
  -webkit-transition: all 0.4s ease 0.2s;
  transition: all 0.4s ease 0.2s;
}
.service-one__single:before {
  top: 0;
  right: 0;
  -webkit-transform: translate(60%, -60%);
  transform: translate(60%, -60%);
}
.service-one__single:after {
  bottom: 0;
  left: 0;
  -webkit-transform: translate(-60%, 60%);
  transform: translate(-60%, 60%);
}
.service-one__icon {
  font-size: 60px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  color: #5dd8d3;
}
.service-one__title {
  margin: 0;
  min-height: 30px;
  font-size: 20px;
  line-height: 34px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 40px;
}
.service-one__title a {
  color: #2e3d62;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.service-one__title a:hover {
  color: coral;
}
.service-one__text {
  color: #666e82;
  font-size: 16px;
  line-height: 34px;
  margin: 0;
  margin-bottom: 25px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.service-one__link {
  display: inline-block;
  vertical-align: middle;
  font-size: 32px;
  color: #bcbfc7;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.frontend {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.service-card__link {
  display: inline-block;
  vertical-align: middle;
  font-size: 32px;
  color: #bcbfc7;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.card-title {
  height: 20px !important;
}
[class*="col-"]:nth-child(1) .service-one__icon {
  color: #5dd8d3;
}
[class*="col-"]:nth-child(1) .service-one__single:hover {
  background-color: #5dd8d3;
}
[class*="col-"]:nth-child(1) .service-one__single:hover .service-one__icon,
[class*="col-"]:nth-child(1) .service-one__single:hover .service-one__title a,
[class*="col-"]:nth-child(1) .service-one__single:hover .service-one__text,
[class*="col-"]:nth-child(1) .service-one__single:hover .service-one__link {
  color: #fff;
}
[class*="col-"]:nth-child(1) .service-one__single:hover:before {
  top: 0;
  right: 0;
  -webkit-transform: translate(30%, -30%);
  transform: translate(30%, -30%);
}
[class*="col-"]:nth-child(1) .service-one__single:hover:after {
  bottom: 0;
  left: 0;
  -webkit-transform: translate(-30%, 30%);
  transform: translate(-30%, 30%);
}

[class*="col-"]:nth-child(2) .service-one__icon {
  color: #9364d4;
}
[class*="col-"]:nth-child(2) .service-one__single:hover {
  background-color: #9364d4;
}
[class*="col-"]:nth-child(2) .service-one__single:hover .service-one__icon,
[class*="col-"]:nth-child(2) .service-one__single:hover .service-one__title a,
[class*="col-"]:nth-child(2) .service-one__single:hover .service-one__text,
[class*="col-"]:nth-child(2) .service-one__single:hover .service-one__link {
  color: #fff;
}
[class*="col-"]:nth-child(2) .service-one__single:hover:before {
  top: 0;
  right: 0;
  -webkit-transform: translate(30%, -30%);
  transform: translate(30%, -30%);
}
[class*="col-"]:nth-child(2) .service-one__single:hover:after {
  bottom: 0;
  left: 0;
  -webkit-transform: translate(-30%, 30%);
  transform: translate(-30%, 30%);
}

[class*="col-"]:nth-child(3) .service-one__icon {
  color: #ee539b;
}
[class*="col-"]:nth-child(3) .service-one__single:hover {
  background-color: #ee539b;
}
[class*="col-"]:nth-child(3) .service-one__single:hover .service-one__icon,
[class*="col-"]:nth-child(3) .service-one__single:hover .service-one__title a,
[class*="col-"]:nth-child(3) .service-one__single:hover .service-one__text,
[class*="col-"]:nth-child(3) .service-one__single:hover .service-one__link {
  color: #fff;
}
[class*="col-"]:nth-child(3) .service-one__single:hover:before {
  top: 0;
  right: 0;
  -webkit-transform: translate(30%, -30%);
  transform: translate(30%, -30%);
}
[class*="col-"]:nth-child(3) .service-one__single:hover:after {
  bottom: 0;
  left: 0;
  -webkit-transform: translate(-30%, 30%);
  transform: translate(-30%, 30%);
}

[class*="col-"]:nth-child(4) .service-one__icon {
  color: #fddd6e;
}
[class*="col-"]:nth-child(4) .service-one__single:hover {
  background-color: #fddd6e;
}
[class*="col-"]:nth-child(4) .service-one__single:hover .service-one__icon,
[class*="col-"]:nth-child(4) .service-one__single:hover .service-one__title a,
[class*="col-"]:nth-child(4) .service-one__single:hover .service-one__text,
[class*="col-"]:nth-child(4) .service-one__single:hover .service-one__link {
  color: #fff;
}
[class*="col-"]:nth-child(4) .service-one__single:hover:before {
  top: 0;
  right: 0;
  -webkit-transform: translate(30%, -30%);
  transform: translate(30%, -30%);
}
[class*="col-"]:nth-child(4) .service-one__single:hover:after {
  bottom: 0;
  left: 0;
  -webkit-transform: translate(-30%, 30%);
  transform: translate(-30%, 30%);
}

/*
* 14. sidebar styles
*/
@media (max-width: 991px) {
  .sidebar {
    margin-top: 50px;
  }
}
.sidebar__title {
  margin: 0;
  color: #2e3d62;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}
.sidebar__single:not(.sidebar__search) {
  padding: 50px;
  border-radius: 4px;
  background-color: white;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(46, 61, 98, 0.1);
  box-shadow: 0px 10px 60px 0px rgba(46, 61, 98, 0.1);
}
.sidebar__single + .sidebar__single {
  margin-top: 30px;
}
.sidebar__post__single + .sidebar__post__single {
  margin-top: 30px;
}
.sidebar__post-image,
.sidebar__post-content {
  display: table-cell;
  vertical-align: middle;
}
.sidebar__post-image {
  width: 60px;
}
.sidebar__post-image .inner-block {
  width: 100%;
  overflow: hidden;
  border-radius: 50%;
}
.sidebar__post-image .inner-block > img {
  width: 100%;
}
.sidebar__post-content {
  padding-left: 20px;
}
.sidebar__post-title {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
}
.sidebar__post-title a {
  color: #666e82;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.sidebar__post-title a:hover {
  color: #ff8257;
}
.sidebar__category-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebar__category-list-item {
  line-height: 1em;
}
.sidebar__category-list-item a {
  color: #666e82;
  font-size: 16px;
  display: inline-block;
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.sidebar__category-list-item a:before {
  content: "\f101";
  font-family: "FontAwesome";
  color: #ff8257;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%) translateX(-15px);
  transform: translateY(-50%) translateX(-15px);
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.sidebar__category-list-item a:hover {
  color: #9364d4;
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
}
.sidebar__category-list-item a:hover:before {
  opacity: 1;
}
.sidebar__category-list-item + .sidebar__category-list-item {
  margin-top: 27px;
}
.sidebar__tags-list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: -10px;
}
.sidebar__tags-list-item {
  display: inline-block;
  vertical-align: middle;
  line-height: 1em;
}
.sidebar__tags-list-item a {
  display: block;
  color: #666e82;
  font-size: 16px;
  line-height: 1em;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.sidebar__tags-list-item a:hover {
  color: #ff8257;
}
.sidebar__search-form {
  width: 100%;
  height: 84px;
  border-radius: 4px;
  background-color: #ff8257;
}
.sidebar__search-form input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: #fff;
  padding-left: 50px;
  padding-right: 50px;
}
.sidebar__search-form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}
.sidebar__search-form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}
.sidebar__search-form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}
.sidebar__search-form input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}
